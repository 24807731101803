import { myAcuvueAPI } from 'src/api';

export const myAcuvue = {
  namespaced: true,

  state: () => ({
    inited: false,
    balance: 0,
    isLock: null,
  }),

  getters: {
    inited: state => state.inited,
    balance: state => state.balance,
    isLock: state => state.isLock,
  },

  actions: {
    async init({ commit, dispatch }) {
      await Promise.all([dispatch('fetchBalance'), dispatch('fetchIsLock')]);

      commit('setInited', true);
    },

    async fetchBalance({ commit }) {
      const result = await myAcuvueAPI.getBalance();
      commit('setBalance', result.balance);
    },

    async fetchIsLock({ commit }) {
      const { result } = await myAcuvueAPI.isLock();
      commit('setIsLock', result);
    },
  },

  mutations: {
    setInited(state, value) {
      state.inited = value;
    },

    setBalance(state, value) {
      state.balance = value;
    },

    setIsLock(state, value) {
      state.isLock = value;
    },
  },
};
